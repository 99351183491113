@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.authHeaderButtonsHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  p {
    color: var(--kp-color-content-regular-secondary);
  }
  .mobileSignUpButton {
    padding: 0;
    font-size: $font-size-small;
    font-weight: 400;
    line-height: 2rem;
    color: var(--kp-color-content-interactive-secondary);
  }
}
