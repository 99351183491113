/**
  * @tokens Shadow
  * @presenter Shadow
  */

$shadow-1: 0px 1px 2px rgba(24, 24, 24, 0.08), 0px 2px 5px rgba(2, 51, 104, 0.1),
  0px 0px 1px rgba(2, 51, 104, 0.03);

$shadow-2: 0px 1px 10px rgba(24, 24, 24, 0.08),
  0px 5px 5px rgba(24, 24, 24, 0.16), 0px 2px 4px rgba(24, 24, 24, 0.35);

$shadow-3: 0 1px 1px 0 rgba(24, 24, 24, 0.1);

$shadow-4: 0px 1px 1px 0px rgba(2, 51, 104, 0.15);

$inner-shadow-1: inset 0px -16px 16px rgba(0, 0, 0, 0.16);
$inner-shadow-2: inset 0px -16px 16px rgba(153, 153, 153, 0.16);
$inner-shadow-3: inset 0px -16px 16px rgba(224, 231, 239, 0.3);
$inner-shadow-4: inset 0px -1px 0px $color-grayscale-elm-1;

/**
  * @tokens DarkShadow
  * @presenter Shadow
  */
$dark-shadow-1: 0px 1px 2px rgba(206, 227, 253, 0.09),
  0px 2px 5px rgba(128, 183, 250, 0.08), 0px 0px 1px rgba(206, 227, 253, 0.3);

$dark-shadow-2: 0px 1px 10px rgba(24, 24, 24, 0.08),
  0px 5px 5px rgba(24, 24, 24, 0.16), 0px 2px 4px rgba(24, 24, 24, 0.35);

$dark-shadow-3: 0 1px 1px 0 rgba(24, 24, 24, 0.1);

$dark-shadow-4: 0px 1px 1px 0px rgba(2, 51, 104, 0.15);

$dark-inner-shadow-1: inset 0px -16px 16px rgba(0, 0, 0, 0.16);
$dark-inner-shadow-2: inset 0px -16px 16px rgba(153, 153, 153, 0.16);
$dark-inner-shadow-3: inset 0px -16px 16px rgba(224, 231, 239, 0.3);
$dark-inner-shadow-4: inset 0px -1px 0px $dark-color-grayscale-elm-1;
