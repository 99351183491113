@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.secondary {
  padding: 0.8rem 1.6rem;
  border-radius: 0.2rem;
  background-color: var(--kp-color-fill-interactive-secondary);
  color: var(--kp-color-content-interactive-secondary);
  box-shadow: $shadow-3;

  &.small {
    padding: 0.4rem 0.8rem;
  }

  @include respond-below(sm) {
    font-weight: bold;
    border-radius: 0.4rem;
  }
}

.secondary:link,
.secondary:visited {
  color: var(--kp-color-content-interactive-secondary);
}

//display default hover state
.secondary:hover {
  background-color: var(--kp-color-fill-interactive-secondary-hover);
}

.secondary:disabled,
.secondary.isDisabled {
  background-color: var(--kp-color-fill-disabled);
  color: var(--kp-color-disabled-dim);
}
