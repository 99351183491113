@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.tertiary2 {
  color: var(--kp-color-content-interactive-secondary);
  border-radius: 0.2rem;
  padding: 0.8rem;
  height: 3.6rem;

  &.small {
    padding: 0.2rem 0.4rem;
    height: 2.4rem;

    @include respond-below(sm) {
      padding: 0.4rem 0;
    }

    > *:not(:last-child) {
      // margin-right: 0.8rem;
    }
  }

  @include respond-below(sm) {
    &.big {
      padding: 1.2rem 0.8rem;
    }

    &.medium {
      padding: 0.8rem 0;
    }
  }
}

.tertiary2:visited,
.tertiary2:link {
  color: var(--kp-color-content-interactive-secondary);
}

.tertiary2:hover {
  background-color: var(--kp-color-fill-neutral-dim);

  @include respond-below(sm) {
    background-color: transparent;
  }
}

.tertiary2:disabled,
.tertiary2.isDisabled {
  background-color: transparent;
  color: var(--kp-color-disabled-dim);
}
