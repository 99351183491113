@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.authHeaderButtonsHolder {
  width: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: flex-end;
  min-width: 19.2rem;
  gap: 2.4rem;
}
